import React, {useCallback, useEffect, useState} from "react";
import {   NumberOutlined } from '@ant-design/icons';
import {Card, Button, Drawer, Badge, Form, Input, ConfigProvider, App, Space} from 'antd';
import PhoneInput, {locale} from "antd-phone-input";
import Home from "./Home";
import {SERVER_URL} from "../Config";


const AuthCheck = () => {


    const { modal, notification, message} = App.useApp();

    const [getPhone,setPhone] = useState(window.localStorage.getItem("phone") == null ? false : window.localStorage.getItem("phone"));
    const [getAuthAccount,setAuthAccount] = useState(false);
    const [getChecking,setChecking] = useState(true);
    const [getLoginPage,setLoginPage] = useState(false);
    const [getLoginPageStep,setLoginPageStep] = useState(1);
    const [getAuthVerifies,setAuthVerifies] = useState(false);
    const [getChats,setChats] = useState([]);


    const validator = (_, {valid}) => {
        // if (valid(true)) return Promise.resolve(); // strict validation
        if (valid()) return Promise.resolve(); // non-strict validation
        return Promise.reject("Invalid phone number");
    }


    useEffect(() => {

        if(getPhone){
            fetch(`${SERVER_URL}/api/${getPhone}/check`, {
                method: "GET",
                redirect: "follow"
            })
                .then((response) => response.json())
                .then((result) => {

                    if(result.status !== "success"){
                        throw new Error(result.error)
                    }

                    setAuthAccount(true)
                    setChecking(false);
                    setAuthVerifies(true)
                })
                .catch((error) => {
                    message.error(error.message)
                });
        }

    }, []);


    useEffect(() => {

        if(getAuthVerifies){
            const requestOptions = {
                method: "GET",
                redirect: "follow"
            };

            fetch(`${SERVER_URL}/api/${getPhone}/chats`, requestOptions)
                .then((response) => response.json())
                .then((result) => {

                    if(result.status !== "success"){
                        throw new Error(result.error)
                    }

                    setChats(result.chats)

                })
                .catch((error) => {
                    message.error(error.message)
                });
        }
    }, [getAuthVerifies]);


    const logoutAccount = () => {

        fetch(`${SERVER_URL}/api/${getPhone}/logout`,{
            method: "POST",
            redirect: "follow"
        })
            .then((response) => response.json())
            .then((result) => {

                if(result.status !== "success"){
                    throw new Error(result.error)
                }

                window.localStorage.removeItem("phone");
                message.success("Çıkış Yapıldı");
                window.location.reload();

            })
            .catch((error) => {
                message.error(error.message)
            });

    }

    const LoginArea = () => {

        if(getPhone){

            return (<Space>
                {getPhone}
                <Button type="primary" icon={<NumberOutlined />} onClick={logoutAccount}>
                    Çıkış Yap
                </Button>
            </Space>);

        }else{

            return(
                <Button type="primary" icon={<NumberOutlined />} onClick={() => setLoginPage(true)}>
                    Oturum Aç
                </Button>
            )
        }
    }

    return (<>

        <Card
            loading={!getAuthAccount}
            size="small"
            title={getPhone ? (getChecking ?   "Kontroller Sağlanıyor" : (getAuthAccount ?  <Space><Badge status="success" />  Telegram Kullanıcı Yönetimi</Space> : "Giriş Yapınız")) : "Giriş Yapınız" }
            extra={<LoginArea/>} >
            <Home />
        </Card>


        <Drawer
            bodyStyle={{flex:1}}
            width={450}
            title="Oturum Açma" onClose={() => {
                setLoginPage(!getLoginPage);
                setLoginPageStep(1);
            }}
            open={getLoginPage}>
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", flex:1, flexDirection:"column"}}>

                {
                    getLoginPageStep === 1 &&
                    <ConfigProvider locale={locale("trTR")}>
                        <Form
                            style={{width:300, height:220}}
                            layout={"vertical"}
                            name="basic"
                            onFinish={(result) => {

                                setPhone(`+${result.username.countryCode}${result.username.areaCode}${result.username.phoneNumber}`);

                                fetch(`${SERVER_URL}/api/+${result.username.countryCode}${result.username.areaCode}${result.username.phoneNumber}/login`, {
                                    method: "POST",
                                    redirect: "follow"
                                })
                                    .then((response) => response.json())
                                    .then((result) => {

                                        if(result.status !== "success"){
                                            throw new Error(result.error)
                                        }
                                        setLoginPageStep(2);
                                        message.success("Giriş kodu gönderildi")

                                    })
                                    .catch((error) => {
                                        message.error(error.message)
                                    });

                            }}
                            onFinishFailed={() => {}}
                            autoComplete="off">

                            <Form.Item
                                label="Telefon Numarası"
                                name="username"
                                rules={[{validator}]}>

                                    <PhoneInput enableSearch country={"tr"} />
                            </Form.Item>

                            <Form.Item>
                                <Button block  type="primary" htmlType="submit">
                                    Kod Gönder
                                </Button>
                                <Button block style={{marginTop:10}}  danger  type="text" onClick={() => setLoginPageStep(3)}>
                                    Giriş Kaldır
                                </Button>
                            </Form.Item>

                        </Form>
                    </ConfigProvider>
                }

                {
                    getLoginPageStep === 3 &&
                    <ConfigProvider locale={locale("trTR")}>
                        <Form
                            style={{width:300, height:220}}
                            layout={"vertical"}
                            name="basic"
                            onFinish={(result) => {

                                setPhone(`+${result.username.countryCode}${result.username.areaCode}${result.username.phoneNumber}`);

                                fetch(`${SERVER_URL}/api/+${result.username.countryCode}${result.username.areaCode}${result.username.phoneNumber}/logout`, {
                                    method: "POST",
                                    redirect: "follow"
                                })
                                    .then((response) => response.json())
                                    .then((result) => {

                                        if(result.status !== "success"){
                                            throw new Error(result.error)
                                        }

                                        setLoginPageStep(1);
                                        message.success("Çıkış Yapıldı")

                                    })
                                    .catch((error) => {
                                        message.error(error.message)
                                    });


                            }}
                            onFinishFailed={() => {}}
                            autoComplete="off">

                            <Form.Item
                                label="Telefon Numarası"
                                name="username"
                                rules={[{validator}]}>

                                    <PhoneInput enableSearch country={"tr"} />
                            </Form.Item>

                            <Form.Item>
                                <Button danger block  type="primary" htmlType="submit">
                                    Giriş Kaldır
                                </Button>
                            </Form.Item>

                            <Button block  type="text" onClick={() => setLoginPageStep(1)}>
                                Giriş Yap
                            </Button>

                        </Form>
                    </ConfigProvider>
                }


                {
                    getLoginPageStep === 2 &&
                        <Form
                            style={{width:300, height:220}}
                            layout={"vertical"}
                            name="basic"
                            onFinish={(result) => {

                                fetch(`${SERVER_URL}/api/${getPhone}/login/${result.code}/code`, {
                                    method: "POST",
                                    redirect: "follow"
                                })
                                    .then((response) => response.json())
                                    .then((result) => {

                                        if(result.status !== "success"){
                                            throw new Error(result.error)
                                        }

                                        window.localStorage.setItem("phone",getPhone);
                                        message.success("Giriş Yapıldı")

                                        setAuthAccount(true);

                                        setLoginPage(false);

                                    })
                                    .catch((error) => {
                                        message.error(error.message)
                                    });
                            }}
                            onFinishFailed={() => {}}
                            autoComplete="off">

                            <span style={{fontWeight:200, fontSize:24}}>
                                {getPhone}
                            </span>

                            <Form.Item
                                style={{paddingTop:25}}
                                label="Giriş kodu"
                                name="code">
                                <Input placehodler={"000000"}/>
                            </Form.Item>

                            <Form.Item>
                                <Button block  type="primary" htmlType="submit">
                                    Giriş Yap
                                </Button>
                            </Form.Item>

                        </Form>
                }
            </div>
        </Drawer>

    </>)
}

export default  AuthCheck;