import React, { useState, useEffect } from 'react';
import {
    List,
    Form,
    Card,
    Row,
    Col,
    Button,
    App,
    Select,
    Transfer,
    Badge,
    Divider,
    Space,
    Input,
    Popconfirm, Timeline, Result, Progress
} from 'antd';
import { PhoneTwoTone,PlusOutlined } from '@ant-design/icons';
import {SERVER_URL} from "../../Config";

const SaveUserToGroupChat = () => {

    const { modal, notification, message} = App.useApp();
    const [getPhone,setPhone] = useState(window.localStorage.getItem("phone") == null ? false : window.localStorage.getItem("phone"));
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [getMembers, setMembers] = useState([]);
    const [getGroups, setGroups] = useState([]);
    const [getRecordStatus,setRecordStatus]  = useState([]);
    const initialTargetKeys = getMembers.filter(item => item.username !== null).map(item => ({ key: item.id, label: `${item.username}`, description: `${item.first_name} ${item.last_name}` })).filter((item) => Number(item.key) > 10).map((item) => item.key);
    const [targetKeys, setTargetKeys] = useState(initialTargetKeys);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };
    const onScroll = (direction, e) => { };
    
    const getGroupsOfDB = () => {

        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch(`https://sardisglobal.com/api/telegram/${getPhone}/telegram_groups`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if(result.status !== 1){
                    throw new Error(result.error)
                }

                setGroups(result.data);

            })
            .catch((error) => {
                message.error(error.message)
            });

    }


    useEffect(getGroupsOfDB,[])
    const CreateSelectfooterGroupCreateForm = () => {

        const [getTitle,setTitle] = useState("");
        const [getLoadingCreateGroup,setLoadingCreateGroup] = useState(false);

        const CreateGroup = () => {

            if(getTitle.length < 3)
                return message.error("Lütfen geçrli bir grup adı giriniz");

            setLoadingCreateGroup(true);
            const formdata = new FormData();
            formdata.append("group_id", (Math.floor(Math.random() * (99999999 - 111111) + 111111)).toString());
            formdata.append("title", getTitle);

            fetch(`https://sardisglobal.com/api/telegram/${getPhone}/telegram_groups`, {
                method: "POST",
                body: formdata,
                redirect: "follow"
            })
                .then((response) => response.json())
                .then((result) => {

                    if(result.status !== 1){
                        throw new Error(result.error)
                    }

                    setLoadingCreateGroup(false);
                    message.success(result.msg);
                    getGroupsOfDB();

                })
                .catch((error) => {
                    setLoadingCreateGroup(false);
                    message.error(error.message)
                });
        }

        return (
            <Space style={{ padding: '0 8px 4px' }}>
                <Input
                    loading={getLoadingCreateGroup}
                    value={getTitle}
                    onChange={e => setTitle(e.target.value)}
                    placeholder="Yeni Grup Adı"
                    onKeyDown={(e) => e.stopPropagation()}
                />
                <Button loading={getLoadingCreateGroup} type="text" icon={<PlusOutlined />} onClick={CreateGroup} >
                    Oluştur
                </Button>
            </Space>
        )
    }

    useEffect(() => {

        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        setLoading(true);
        fetch(`${SERVER_URL}/api/${getPhone}/chats`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if(result.status !== "success"){
                    throw new Error(result.error)
                }

                setData(result.chats);
                setLoading(false)

            })
            .catch((error) => {
                message.error(error.message)
                setLoading(false)
            });

    }, []);


    const getGroupMembers = (groupID) => {

        setLoading(true)

        const formdata = new FormData();
        formdata.append("group_id", groupID);

        const requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow"
        };

        fetch(`${SERVER_URL}/api/${getPhone}/group/chat/active_members`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if(result.status !== "success"){
                    throw new Error(result.error)
                }

                setMembers(result.result);
                setLoading(false)

            })
            .catch((error) => {
                message.error(error.message)
                setLoading(false)
            });


    }

    const onSubmitForm =  async (values) => {

        const getUsersSelected = getMembers.filter(item => values.members.includes(item.id))

        const formdata = new FormData();
        formdata.append("members", JSON.stringify(getUsersSelected));
        formdata.append("group_id", values.group);

        setLoading(true)
        fetch(`https://sardisglobal.com/api/telegram/${getPhone}/telegram_members/bulkstore`, {
            method: "POST",
            body: formdata,
            redirect: "follow"
        })
            .then((response) => response.json())
            .then((result) => {

                if(result.status !== 1){
                    throw new Error(result.error)
                }

                message.success(result.msg);
                setLoading(false)

            })
            .catch((error) => {
                message.error(error.message)
                setLoading(false)
            });



    }

    return(<>

        <Form
            style={{width:"100%"}}
            onFinish={onSubmitForm}
            disabled={loading}
            layout={"vertical"}
            name="customized_form_controls">

            <Form.Item
                label={"Kanal Seçimi"}
                name="channel">
                <Select
                    loading={loading}
                    style={{width:"100%",}}
                    showSearch
                    placeholder="Kanal Seçiniz"
                    optionFilterProp="label"
                    onChange={getGroupMembers}
                    onSearch={() => console.log("onSearch")}
                    options={data.filter(item => ["Channel"].includes(item.type)).map(item => ({label: item.title, value:item.id}))}
                />
            </Form.Item>

            <Form.Item
                label={"Kullanıcı Seçimi"}
                name="members">
                <Transfer
                    showSearch
                    listStyle={{
                        width: "100%",
                        height: 400,
                    }}
                    titles={['Kanal Kullanıcıları', 'Seçilen Kullanıcılar']}
                    targetKeys={targetKeys}
                    selectedKeys={selectedKeys}
                    onChange={onChange}
                    onSelectChange={onSelectChange}
                    onScroll={onScroll}
                    onSearch={(a,b) => console.log(a,b)}
                    dataSource={getMembers.filter(item => item.username !== null).map(item => ({ key: item.id, label: <> {item.first_name} {item.last_name} <span>@{item.username}</span> {item.phone && <PhoneTwoTone />} </>, description: `${item.first_name} ${item.last_name}`,...item }))}
                    render={(item) => item.label}
                    filterOption={(input, item) => item.username?.includes(input) || item.first_name?.includes(input) || item.last_name?.includes(input)}
                />
            </Form.Item>

            <Form.Item
                label={"Grup Seçimi"}
                name="group">
                <Select
                    loading={loading}
                    style={{width:"100%",}}
                    showSearch
                    placeholder="Grup Seçiniz"
                    optionFilterProp="label"
                    onSearch={() => console.log("onSearch")}
                    options={getGroups.map(item => ({value: item.id, label: item.title}))}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <CreateSelectfooterGroupCreateForm/>
                        </>
                    )}
                />
            </Form.Item>

            <Form.Item>
                <Button block type="primary" htmlType="submit">
                    Kullanıcıları Aktar
                </Button>
            </Form.Item>


        </Form>

    </>)
}


export default SaveUserToGroupChat;