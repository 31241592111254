import React from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import AuthCheck from "./Pages/AuthCheck";
const { Header, Content, Footer } = Layout;

const items = [
    {
        key: "home_index",
        label: "Anasayfa",

    }
];


const App = () => {

    const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();

    return (
      <Layout style={{flex:1, height:"100vh", padding:0, margin:0, width:"100%", display:"flex"}}>
        <Content  style={{padding: '0 48px'}}>
          <Breadcrumb  style={{ margin: '16px 0'}}>
            <Breadcrumb.Item>Telegram</Breadcrumb.Item>
            <Breadcrumb.Item>Kullanıcı Yönetimi</Breadcrumb.Item>
          </Breadcrumb>
            <AuthCheck/>
        </Content>
        <Footer
            style={{ textAlign: 'center' }}>
          Development by <strong>Thecoder</strong> © {new Date().getFullYear()}
        </Footer>
      </Layout>
    );

};


export default App;