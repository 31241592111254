import React, {useEffect, useState} from 'react';
import {App, Button, Select, Space, Table, Tag} from 'antd';
import { SendOutlined } from '@ant-design/icons';
import {SERVER_URL} from "../../Config";

const { Column, ColumnGroup } = Table;

const InviteButton = ({channel, username, loadingStatus = false, onClick = () => {}}) => {

    const { modal, notification, message} = App.useApp();
    const [getPhone,setPhone] = useState(window.localStorage.getItem("phone") == null ? false : window.localStorage.getItem("phone"));

    const sendReq = () => {

        if(channel === null){
            return message.error("Hedef kanal seçiniz");
        }

        const formdata = new FormData();
        formdata.append("channel_id", channel);
        formdata.append("username", username);


        onClick(true);

        fetch(`${SERVER_URL}/api/${getPhone}/invite`, {
            method: "POST",
            body: formdata,
            redirect: "follow"
        })
            .then((response) => response.json())
            .then((result) => {

                if(result.status !== "success"){
                    throw new Error(result.message)
                }


                message.success(result.message);

            })
            .catch((error) => {
                onClick(false);
                message.error(error.message);
            });

    }

    return(<Button icon={<SendOutlined />} loading={loadingStatus} disabled={loadingStatus} onClick={sendReq}> { loadingStatus ? "İşlemde" : "Davet Et"} </Button>)


}


const ManuelInviteMemberToChannel = () => {

    const { modal, notification, message} = App.useApp();

    const [getPhone,setPhone] = useState(window.localStorage.getItem("phone") == null ? false : window.localStorage.getItem("phone"));

    const [isDisabled, setIsDisabled] = useState(false);


    const [getLoading,setLoading] = useState(false);

    const [getGroup,setGroup]           = useState(null);
    const [getChannel,setChannel]       = useState(null);

    const [getGroups,setGroups]         = useState([]);
    const [getChannels,setChannels]     = useState([]);
    const [getMembers,setMembers]       = useState([]);


    useEffect(() => {


        setLoading(true);


        fetch(`${SERVER_URL}/api/${getPhone}/chats`, {
            method: "GET",
            redirect: "follow"
        })
            .then((response) => response.json())
            .then((result) => {

                if(result.status !== "success"){
                    throw new Error(result.error)
                }

                setChannels(result.chats);

            })
            .catch((error) => {
                message.error(error.message)
            });

        fetch(`https://sardisglobal.com/api/telegram/${getPhone}/telegram_groups`, {
            method: "GET",
            redirect: "follow"
        })
            .then((response) => response.json())
            .then((result) => {

                if(result.status !== 1){
                    throw new Error(result.error)
                }

                console.log(result.data)
                setGroups(result.data);

            })
            .catch((error) => {
                message.error(error.message)
            });

        setLoading(false)

    }, []);

    useEffect(()=> {

       if(getGroup !== null){
           setLoading(true);
           fetch(`https://sardisglobal.com/api/telegram/${getPhone}/telegram_members?group_id=${getGroup}&per_page=10000`, {
               method: "GET",
               redirect: "follow"
           })
               .then((response) => response.json())
               .then((result) => {

                   if(result.status !== 1){
                       throw new Error(result.error)
                   }

                   setLoading(false);
                   setMembers(result.data.data.map(item => ({ id: item.member_id, username: item.member_username, name: `${item.member_name} ${item.member_surname ?? ''}` })));

               })
               .catch((error) => {
                   setLoading(false);
                   message.error(error.message)
               });
       }


    },[getGroup])



    return(<>

       <Space style={{marginBottom:15}}>
           <Select
               loading={getLoading}
               style={{width: 300, marginRight:15}}
               showSearch
               placeholder="Grup Seçiniz"
               optionFilterProp="label"
               onChange={(e) => {

                   setGroup(e)


               }}
               onSearch={() => console.log("onSearch")}
               options={getGroups.map(item => ({value: item.id, label: item.title}))}
           />
           <Select
               loading={getLoading}
               style={{width: 300}}
               showSearch
               placeholder="Grup Seçiniz"
               optionFilterProp="label"
               onChange={(value) => {
                   setChannel(value);
               }}
               onSearch={() => console.log("onSearch")}
               options={getChannels.filter(item => ["Channel"].includes(item.type)).map(item => ({label: item.title, value:item.id}))}
           />

           {
               /*
               <Button onClick={() => {

               const formdata = new FormData();
               formdata.append("channel_id", getChannel);

               getMembers.map(item => formdata.append("username[]", item.username))

               const requestOptions = {
                   method: "POST",
                   body: formdata,
                   redirect: "follow"
               };

               fetch(`${SERVER_URL}/api/${getPhone}/invite/multiple`, requestOptions)
                   .then((response) => response.text())
                   .then((result) => console.log(result))
                   .catch((error) => console.error(error));

           }}>
               Hepsine Gödner
           </Button>
               * */
           }
       </Space>

        <Table dataSource={getMembers} loading={getLoading}>
            <Column title="Kullanıcı Adı" dataIndex="username" key="username" />
            <Column title="Address" dataIndex="name" key="name" />
            <Column
                title="İşlem"
                key="id"
                render={(_, record) => (
                   <InviteButton loadingStatus={isDisabled} channel={getChannel} username={record.username} onClick={() =>{

                       setIsDisabled(true);

                       const randomDelay = Math.floor(Math.random() * (180000 - 60000 + 1)) + 60000;

                       setTimeout(() => {
                           setIsDisabled(false);
                       }, randomDelay);

                   }} />
                )}
            />
        </Table>


    </>)

}


export default ManuelInviteMemberToChannel;