import React, {useRef, useState} from "react";
import {Space, Button, Card, Tour, Modal, App} from "antd";
import SaveUserToGroup from "./SubPages/SaveUserToGroup";
import SaveUserToGroupChat from "./SubPages/SaveUserToGroupChat";
import ManuelInviteMemberToChannel from "./SubPages/ManuelInviteMemberToChannel";


const Home = () => {

    const { notification, message} = App.useApp();


    const [open, setOpen] = useState(false);
    const [getPage, setPage] = useState("null");

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    const [openTour, setOpenTour] = useState(false);
    const [openTourAsk, setOpenTourAsk] = useState(window.localStorage.getItem("tour") == null ? true : !(window.localStorage.getItem("tour") === "ok"));


    const steps = [
        {
            title: 'Grup Kişi Yönetimi',
            description: 'Bu seçenek üzerinden yöneticisi yada açık üyeleri olan gruplardaki kişileri kolayca çekebilmenize yarar.',
            target: () => ref1.current,
        },
        {
            title: 'Aktif Kişi Çekme',
            description: 'Bu seçenek üzerinden yöneticisi yada açık üyeleri olan gruplardaki aktif chat"e katılan kişileri kolayca çekebilmenize yarar.',
            target: () => ref2.current,
        },
        {
            title: 'Davet Gönderme',
            description: 'Bu seçenek oluşturdugunuz kişileri istediginiz yöneticisi oldugunuz gruba çekmenize yarar ve bu işlem her 1 ile 3 dakika arasında bir işlem yapmanıza yarar.',
            target: () => ref3.current,
        },
    ];


    return(<>

        <Space>

            <Button ref={ref1} type="primary" onClick={() => {
                setPage("SaveUserToGroup");
                setOpen(true);
            }}>Kanal'dan Aktarma</Button>

            <Button ref={ref2} type="primary" onClick={() => {
                setPage("SaveUserToGroupChat");
                setOpen(true);
            }}>Kanald'dan Aktif Kullanıcıları Aktarma</Button>

            <Button ref={ref3} type="primary" onClick={() => {
                setPage("ManuelInviteMemberToChannel");
                setOpen(true);
            }}>Manuel Davet</Button>

        </Space>



        {
            open &&
            <Card
                style={{width:"100%", marginTop:25}}
                title={
                <>
                    { getPage === "SaveUserToGroup" && "Kanal'dan Aktarma" }
                    { getPage === "SaveUserToGroupChat" && "Kanald'dan Aktif Kullanıcıları Aktarma" }
                    { getPage === "ManuelInviteMemberToChannel" && "Manuel Davet" }
                </>
            }>
                { getPage === "SaveUserToGroup" && <SaveUserToGroup/> }
                { getPage === "SaveUserToGroupChat" && <SaveUserToGroupChat/> }
                { getPage === "ManuelInviteMemberToChannel" && <ManuelInviteMemberToChannel/> }
            </Card>
        }





        <Tour type={"primary"}   open={openTour} onClose={() => setOpenTour(false)} onFinish={() => {
            window.localStorage.setItem("tour","ok");
        }} steps={steps} />


        <Modal
            open={openTourAsk}
            title="Desktek"
            onOk={() => {
                setOpenTourAsk(false)
                setOpenTour(true);
            }}
            onCancel={() => {
                setOpenTourAsk(false)
            }}
            okText={"Tura Katıl"}
            cancelText={"Kapat"}
            footer={(_, { OkBtn, CancelBtn }) => (
                <>
                    <Button danger onClick={() => {
                        window.localStorage.setItem("tour","ok");
                        setOpenTourAsk(false)
                        setOpenTour(false);

                    }}>Tekrar Gösterme</Button>
                    <CancelBtn  />
                    <OkBtn />
                </>
            )}
        >
            Bu tura katılarak ekranda bulunan tuşların ne işe yaradığını öğrenebilirsiniz.
        </Modal>


    </>)
};



export  default Home;
