import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./app.css"
import {App as AntApp, ConfigProvider} from "antd"
import trTR from 'antd/locale/tr_TR';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ConfigProvider locale={trTR}>
        <AntApp style={{flex:1}}    >
            <App />
        </AntApp>
      </ConfigProvider>
  </React.StrictMode>
);