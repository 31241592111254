import React from "react";


const ENV_PROD = true;



const SERVER_URL_PORD = "https://telegramserver.emailsystem365.com";
const SERVER_URL_DEV = "http://localhost:1011";

const SERVER_URL = ENV_PROD ? SERVER_URL_PORD : SERVER_URL_DEV;



export {
    SERVER_URL
}